import React from 'react';
import {
	Button,
	Stack,
	Grid,
	Paper,
	Typography
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';


export const MyCalculatorBox = (
	{
		calculatorName,
		calculatorId
	}
) => {
	const editPath = `/editCalculator/${calculatorId}`;
	const testPath = `/testCalculator/${calculatorId}`;
	const previewPath = `/previewCalculator/${calculatorId}`;

	return (
		<Paper>
			<Grid
				container
				alignItems='center'
				justifyContent='space-between'
				p={2}
			>
				<Grid item>
					<Typography variant='h4'>{calculatorName}</Typography>
				</Grid>
				<Grid item container flexDirection='row' columnGap={2} justifyContent='flex-end' xs='auto'>
					<Grid item>
						<Stack spacing={2}>
							<Button
								fullWidth
								variant='contained'
								component={RouterLink}
								to={previewPath}
							>
								Preview
							</Button>
						</Stack>
					</Grid>
					<Grid item>
						<Stack spacing={2}>
							<Button
								fullWidth
								variant='contained'
								component={RouterLink}
								to={editPath}
							>
								Edit
							</Button>
							<Button
								fullWidth
								variant='contained'
								component={RouterLink}
								to={testPath}
							>
								Test
							</Button>
						</Stack>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};
