import React from 'react';
import {
	TextField,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form'; 

export const FormStepInput = ({ input, disabled }) => {
	const { register, watch, control, formState: { errors } } = useFormContext();
	let regex = '';
	if (input.validationRegex) {
		regex = new RegExp('^' + input.validationRegex + '*$');
	}
	return (
		<>
			{(input.inputType === 'input') &&
			<Grid item xs={6}>
				<Controller
					control={control}
					name={'variables'}
					render={() => (
						<TextField
							fullWidth
							{...register(`variables.${input.dataName}`, { required: input.required, pattern: {value: regex, message: input.errorMessage}})}
							value={watch(`variables.${input.dataName}`) || ''}
							required={input.required}
							error={errors?.variables?.[input.dataName] ? true : false}
							helperText={errors?.variables?.[input.dataName]?.message}
							variant='outlined'
							label={input.label}
							disabled={disabled}
						/>
					)}
				/>
			</Grid>
			}
			{(input.inputType === 'select') &&
			<Grid item xs={6}>
				<FormControl fullWidth>
					<InputLabel>{input.name}</InputLabel>
					<Select
						{...register(`variables.${input.dataName}`, { required: true })}
						label={input.label}
						value={watch(`variables.${input.dataName}`) || ''}
						error={errors?.variables?.[input.dataName] ? true : false}
						disabled={disabled}
						onClose={() => {
							setTimeout(() => {
								document.activeElement.blur();
							}, 0);
						}}
					>
						{
							input?.options?.map(option =>
								<MenuItem key={option.id} value={option.value}>
									{option.name}
								</MenuItem>
							)
						}
					</Select>
				</FormControl>
			</Grid>
			}
		</>
	);
};
