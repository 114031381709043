import React from 'react';
import { Grid } from '@mui/material';
import {
	CalculatorProvider,
	SnackbarProvider
} from '../utilities';
import { PreviewCalculator } from '../components/previewcalculator';

export const PreviewCalculatorPage = () => {

	return (
		<CalculatorProvider>
			<SnackbarProvider>
				<Grid container justifyContent='space-evenly'>
					<Grid item xs={8} >
						<PreviewCalculator />
					</Grid>
				</Grid>
			</SnackbarProvider>
		</CalculatorProvider>
	);
};
