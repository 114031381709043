import React from 'react';
import {
	Box,
	Typography,
	Stack,
	Button
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export const HomeBox = () => {
	const { user } = useAuth0();

	return (
		<Box display='flex' sx={{mt:2}} justifyContent='space-evenly' flexDirection='column' alignItems='center'>
			<Typography variant='h3'>CalcGenie</Typography>
			<Typography variant='h4' component="h3">{user.name} </Typography>
			<Stack>
				<Button variant='contained' sx={{mt:2, mb:2}} component={RouterLink} to='/myCalculators'>My calculators</Button>
			</Stack>
		</Box>
	);
};
