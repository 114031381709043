import React from 'react';
import {
	Select,
	MenuItem,
	FormControl,
	InputLabel
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
	useCalculatorContext
} from '../../../utilities';

export const ConditionalParameter = ({ index, expression }) => {
	const { calculator } = useCalculatorContext();
	const { register, watch, formState: { invalid } } = useFormContext();

	return (
		<FormControl fullWidth>
			<InputLabel>Conditional Parameter</InputLabel>
			<Select
				{...register(`rules.${index}.resultParameter`, { required: true })}
				value={watch(`rules.${index}.resultParameter`) || ''}
				label='Conditional Parameter'
				error={invalid}
				onClose={() => {
					setTimeout(() => {
						document.activeElement.blur();
					}, 0);
				}}
			>
				{calculator?.parameterList.map(parameter => {
					if (expression.parameters.includes(parameter.id) || parameter.id === expression.result.id) {
						return (<MenuItem
							key={parameter.id}
							value={parameter.id}
						>{parameter.name}</MenuItem>);
					}
				})}
			</Select>
		</FormControl>
	);
};
