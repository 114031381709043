import React from 'react';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography
} from '@mui/material';
import { EditCalculationForm } from './';
import { ConditionList } from '../conditions/';
import {
	useCalculatorContext
} from '../../utilities';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const Calculation = ( {index} ) => {
	const { calculator } = useCalculatorContext();

	return (
		<>
			<Accordion
				disableGutters
			>
				<AccordionSummary
					sx={{
						backgroundColor: 'lightgrey'
					}}
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography variant='h5'>Conditions</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<ConditionList expressionId={calculator.expressionList[index].id} expression={calculator.expressionList[index]} index={index}/>
				</AccordionDetails>
			</Accordion>
			<Accordion disableGutters>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography variant='h5'>{calculator.expressionList[index].name}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<EditCalculationForm index={index}/>
				</AccordionDetails>
			</Accordion>
		</>
	);
};