import React from 'react';
import { Grid } from '@mui/material';

export const NoPage = () => {
	return (
		<Grid container justifyContent='space-evenly'>
			<Grid item>
				<h2>404 - NoPage</h2>
			</Grid>
		</Grid>
	);
};
