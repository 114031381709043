import React from 'react';
import {
	Select,
	MenuItem,
	FormControl,
	InputLabel
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
	useCalculatorContext
} from '../../../utilities';

export const DisplayCalculation = ({ expressionId }) => {
	const { calculator } = useCalculatorContext();
	const { register, watch, formState: { invalid } } = useFormContext();

	return (
		<FormControl fullWidth>
			<InputLabel>Display Calculation</InputLabel>
			<Select
				{...register('displayCalculation', { required: true })}
				error={invalid}
				value={watch('displayCalculation') || ''}
				label='Display Calculation'
				onClose={() => {
					setTimeout(() => {
						document.activeElement.blur();
					}, 0);
				}}
			>
				{calculator?.expressionList.map((expression, index) => {
					if (expressionId != calculator.expressionList[index].id) {
						return <MenuItem key={expression.id} value={expression.id}>{expression.name}</MenuItem>;
					}
				}
				)}
			</Select>
		</FormControl>
	);
};
