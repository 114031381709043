import {
	TextField,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';

export const TestInput = ({parameter, calculationIndex, expression}) => {
	const { register, watch, control, formState: { errors } } = useFormContext();
	let regex = '';
	if (parameter.validationRegex) {
		regex = new RegExp('^' + parameter.validationRegex + '*$');
	}

	return (
		<Grid container item>
			{(parameter.inputType === 'input') &&
			<Grid item>
				<Controller
					control={control}
					name={'calculation'}
					render={() => (
						<TextField
							{...register(`calculation.${calculationIndex}.expressionId`, { value: expression.id})}
							{...register(`calculation.${calculationIndex}.variables.${parameter.dataName}`, { required: true, pattern: {value: regex, message: parameter.errorMessage}})}
							value={watch(`calculation.${calculationIndex}.variables.${parameter.dataName}`) || ''}
							required={parameter.required}
							error={errors?.calculation?.[calculationIndex]?.variables?.[parameter.dataName] ? true : false}
							helperText={errors?.calculation?.[calculationIndex]?.variables?.[parameter.dataName]?.message}
							variant='outlined'
							label={parameter.label}
						/>
					)}
				/>
			</Grid>
			}
			{(parameter.inputType === 'select') &&
			<FormControl sx={{ minWidth: 200 }}>
				<InputLabel>{parameter.label}</InputLabel>
				<Select
					{...register(`calculation.${calculationIndex}.variables.${parameter.dataName}`, { required: true })}
					label={parameter.label}
					value={watch(`calculation.${calculationIndex}.variables.${parameter.dataName}`) || ''}
					onClose={() => {
						setTimeout(() => {
							document.activeElement.blur();
						}, 0);
					}}
				>
					{
						parameter?.options?.map(option =>
							<MenuItem key={option.id} value={option.value}>
								{option.name}
							</MenuItem>
						)
					}
				</Select>
			</FormControl>
			}
		</Grid>
	);
};
