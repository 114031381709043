import React from 'react';
import {
	Grid,
	Typography
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { HomeBox } from '../components/HomeBox';
import { SnackbarProvider } from '../utilities';
import { Loading } from '../components/Loading';

export const HomePage = () => {
	const {isLoading, isAuthenticated } = useAuth0();

	if(isLoading) {
		return (
			<Loading text="Loading..."/>
		);
	}
	return (
		<SnackbarProvider>
			<Grid container justifyContent='space-evenly'>
				{isAuthenticated && (
					<Grid item>
						<HomeBox />
					</Grid>
				)}
				{!isAuthenticated && (
					<Grid item mt={4}>
						<Typography variant='h4' component='h2'>Please Login</Typography>
						<Typography component='p'>Login to access your calculators.</Typography>
					</Grid>
				)}
			</Grid>
		</SnackbarProvider>
	);
};
