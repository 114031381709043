import {
	Box,
	Typography
} from '@mui/material';
import React from 'react'; 
import { Puff } from 'react-loader-spinner';

export const Loading = ({text, align = 'center'}) => {
	return (
		<Box mt={2} display='flex' flexDirection='column' alignItems={align}>
			<Puff color='#000' />
			<Typography sx={{ mt: 2 }}>{text}</Typography>
		</Box>);
};