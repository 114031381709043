import React from 'react';
import {
	ModalProvider,
	CalculatorProvider,
	SnackbarProvider,
} from '../utilities';
import { ParameterList } from '../components/parameters';
import { CalculationList } from '../components/calculations/';
import { Box, Button, Drawer } from '@mui/material';

const drawWidth = 350;

export const EditCalculatorPage = () => {
	const [mobileViewOpen, setMobileViewOpen] = React.useState(false);

	const handleToggle = () => {
		setMobileViewOpen(!mobileViewOpen);
	};
	return (
		<CalculatorProvider>
			<SnackbarProvider>
				<ModalProvider>
					<Box sx={{ 
						display: 'flex'
					}}>
						<Box
							component="nav"
							sx={{ height: '100%',	minHeight: '100%', minWidth: { md: drawWidth }, width: { md: '20%' }, 
								flexShrink: { md: 0 } }}
						>
							<Drawer
								variant='temporary'
								open={mobileViewOpen}
								onClose={handleToggle}
								ModalProps={{
									keepMounted: true,
								}}
								sx={{
									display: { xs: 'block', md: 'none' },
									'& .MuiDrawer-paper': {
										boxSizing: 'border-box',
										width: drawWidth,
										height: '100%'
									},
								}}
							>
								<ParameterList />
							</Drawer>
							<Drawer
								variant='permanent'
								sx={{
									display: { xs: 'none', md: 'block' },
									'& .MuiDrawer-paper': {
										boxSizing: 'border-box',
										width: '100%',
										minwWidth: drawWidth,
										position: 'static',
										height: '100%',
										minHeight: '100%'
									},
								}}
								open
							>
								<ParameterList />
							</Drawer>
						</Box>
						<Box
							component='main'
							sx={{
								flexGrow: 1,
								p: 3,
								marginLeft: 'auto',
								marginRight: 'auto',
								maxWidth:'1280px',
								width: { md: `calc(100% - ${drawWidth}px)` },
							}}
						>
							<Button
								variant='outlined'
								onClick={handleToggle}
								sx={{ mr: 2, display: { md: 'none' } }}
							>
								Parameters
							</Button>
							<CalculationList />
						</Box>
					</Box>
				</ModalProvider>
			</SnackbarProvider>
		</CalculatorProvider>
	);
};
