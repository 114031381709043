import React, { useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Grid,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Button,
	Box
} from '@mui/material';
import {
	LogicType,
	DisplayCalculation,
	Rule
} from './inputFields';
import {
	DeleteConditionPrompt
} from './';
import {
	useForm,
	FormProvider,
	useFieldArray
} from 'react-hook-form';
import {
	useCalculatorContext,
	useSnackbarContext,
	useModalContext
} from '../../utilities';

export const Condition = ({condition, index, expressionId, expression}) => {
	const { calculator, editCondition, update, setUpdate } = useCalculatorContext();
	const { showModal, setModalChildren } = useModalContext();
	const { showSnackbar } = useSnackbarContext();
	const { formState: {isValid, isDirty, errors, invalid}, control, ...methods } = useForm({
		mode: 'all',
		shouldUnregister: true,
		defaultValues: {
			conditionList: [
				{
					calculatorId: calculator.calculatorId,
					conditionId: condition.conditionId,
					expressionId: expressionId,
					order: index,
					conditionLogicType: condition.conditionLogicType,
					displayCalculation: condition.displayCalculation,
					rules: condition.rules
				}
			]
		}
	});
	const { fields, append, remove } = useFieldArray({
		control,
		mode: 'all',
		name: 'rules',
		rules: { minLength: 1 },
		shouldUnregister: true
	});
	const conditionBody = methods.watch();

	const onSubmit = async () => {
		conditionBody.conditionId = condition.conditionId;
		conditionBody.calculatorId = condition.calculatorId;
		conditionBody.expressionId = expressionId;
		conditionBody.order = index;
		let res = await editCondition(conditionBody);
		if ( res && res.status === 204) {
			showSnackbar('Condition edited', 'success');
			setUpdate(!update);
		} else {
			showSnackbar('Condition NOT edited', 'error');
		}
	};

	const handleDelete = async () => {
		showModal();
		setModalChildren(<DeleteConditionPrompt condition={condition} index={index}/>);
	};

	useEffect(() => {
		methods.reset(condition);
	}, [condition]);
	
	return (
		<FormProvider {...{formState: {isValid, isDirty, errors, invalid}, ...methods}}>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
					>
						<Typography>Condition - {index+1}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid container item xs={12}>
							<Grid container item flexDirection='row' xs={6}>
								<DisplayCalculation expressionId={expressionId}/>
							</Grid>
							<Grid container item flexDirection='row' xs={6} justifyContent='center'>
								<LogicType />
							</Grid>
						</Grid>
						<Grid item container xs={12}>
							<Box
								sx={{
									overflowY: 'auto',
									maxHeight: '500px',
									width: '100%'
								}}>
								{ (methods.watch('rules') < 1) &&
									<Grid item container xs={12} justifyContent='space-evenly' marginTop={2}>
										<Typography>Add at least one rule</Typography>
									</Grid>
								}
								{fields.map((field, index) =>
									<Rule index={index} expression={expression} expressionId={expressionId} remove={remove} key={field.id}></Rule>
								)}
							</Box>
						</Grid>
						<Grid item container justifyContent='space-between' marginTop={2} xs={12}>
							<Grid item container xs={6}>
								<Button
									variant='contained'
									onClick={handleDelete}
									color='error'
								>Delete Condition</Button>
							</Grid>
							<Grid container item columnGap={2} xs={6} justifyContent='flex-end'>
								<Grid item>
									<Button
										variant='contained'
										onClick={() => append({})}
									>
										Add Rule
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant='contained'
										type='submit'
										disabled={!isValid || methods.watch('rules') < 1}
									>
										Save Condition
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			</form>
		</FormProvider>
	);
};
