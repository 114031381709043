import React from 'react';
import {
	FormControl,
	Select,
	MenuItem,
	InputLabel
} from '@mui/material';

import { useFormContext } from 'react-hook-form';

export const Operator = ({ index }) => {
	const { register, watch } = useFormContext();

	return (
		<FormControl fullWidth>
			<InputLabel>Operator</InputLabel>
			<Select
				{...register(`rules.${index}.operator`, { required: true })}
				value={watch(`rules.${index}.operator`) || ''}
				label='Operator'
				onClose={() => {
					setTimeout(() => {
						document.activeElement.blur();
					}, 0);
				}}
			>
				<MenuItem value='Equals'>=</MenuItem>
				<MenuItem value='NotEquals'>!=</MenuItem>
				<MenuItem value='LessThan'>{'<'}</MenuItem>
				<MenuItem value='GreaterThan'>{'>'}</MenuItem>
				<MenuItem value='LessThanOrEquals'>{'<='}</MenuItem>
				<MenuItem value='GreaterThanOrEquals'>{'>='}</MenuItem>
			</Select>
		</FormControl>
	);
};
