import React from 'react';
import {
	Alert,
	Select,
	Button,
	MenuItem,
	FormControl,
	InputLabel,
	Grid,
	Box,
	TextField,
	IconButton,
	Typography,
} from '@mui/material';
import {
	useForm,
	useFieldArray
} from 'react-hook-form';
import {
	useModalContext,
	useCalculatorContext,
	useSnackbarContext
} from '../../utilities';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import camelCase from 'camelcase';

export const EditParameterForm = () => {
	const { closeModal } = useModalContext();
	const { showSnackbar }= useSnackbarContext();
	const { currentParameter, setUpdate, update, editParameter } = useCalculatorContext();
	const { register, handleSubmit, watch, getValues, control, formState: { errors } } = useForm({
		shouldUnregister: true,
		defaultValues: {
			calculatorId: currentParameter.calculatorId,
			dataName: currentParameter.dataName,
			dataType: currentParameter.dataType,
			defaultValue: currentParameter.defaultValue,
			errorMessage: currentParameter.errorMessage,
			id: currentParameter.id,
			inputType: currentParameter.inputType,
			name: currentParameter.name,
			required: currentParameter.required,
			validationRegex: currentParameter.validationRegex,
			sourceCompundId: currentParameter.sourceCompundId,
			options: currentParameter.options,
			value: currentParameter.value,
			label: currentParameter.label
		}
	});
	const { fields, append, remove } = useFieldArray({
		control, 
		name: 'options',
		rules: { minLength: 2 },
		shouldUnregister: true
	});
	const inputTypes = {
		constant: 'constant',
		select: 'select',
		inputField: 'input',
		result: 'result'
	};
	const errorMessage = 'Input must be a number. Enter decimals with period.';
	const dataName = watch('name');

	const onSubmit = async () => {
		let parameterBody = getValues();
		parameterBody.dataName = camelCase(dataName);
		if (parameterBody.inputType === 'select' && parameterBody.options.length < 2) {
			return;
		}
		let res = await editParameter(parameterBody);
		if (res && res.status === 204) {
			showSnackbar('Parameter edited', 'success');
			setUpdate(!update);
		} else {
			showSnackbar('Error while editing parameter', 'error');
		}
		closeModal();
	};
	
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box>
				<Typography variant="h5" gutterBottom>Edit Parameter</Typography>
				<Grid item container xs={12} flexDirection='column' spacing={2}>
					<Grid item xs={12}>
						<TextField
							required
							{...register('name', { required: true })}
							variant='outlined'
							fullWidth
							label='Parameter Name'
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth>
							<InputLabel>Input Type</InputLabel>
							<Select
								{...register('inputType', { required: true })}
								label='Input Type'
								value={watch('inputType')}
								onClose={() => {
									setTimeout(() => {
										document.activeElement.blur();
									}, 0);
								}}
							>
								<MenuItem value={inputTypes.constant}>Constant</MenuItem>
								<MenuItem value={inputTypes.select}>Select</MenuItem>
								<MenuItem value={inputTypes.inputField}>Input Field</MenuItem>
								<MenuItem value={inputTypes.result}>Result</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{(watch('inputType') === inputTypes.constant) &&
					<Grid item xs={12}>
						<TextField
							required
							{...register('value', { required: true, pattern: { value: /^[+-]?([0-9]*[.])?[0-9]*$/i, message: errorMessage}})}
							error={errors.value ? true : false}
							helperText={errors.value?.message}
							variant='outlined'
							fullWidth
							label='Value'
						/>
					</Grid>
					}
					{(watch('inputType') === inputTypes.select) &&
					<>
						<Grid item xs={12}>
							<TextField
								{...register('label')}
								error={errors.label ? true : false}
								helperText={errors.label?.message}
								variant='outlined'
								fullWidth
								label='Label'
							/>
						</Grid>
						<Grid container item>
							<Box
								paddingTop={2}
								sx={{
									overflow: 'hidden',
									overflowY: 'auto',
									maxHeight: '20rem'
								}}>
								<Grid item container flexDirection='column' rowGap={2}>
									{fields.map((field, index) =>
										<Grid item container xs={12} key={field.id} flexDirection='row' columnSpacing={2} rowSpacing={2} borderTop={1} marginBottom={2}>
											<Grid item container xs={10} spacing={2}>
												<Grid item xs={12}>
													<TextField
														required
														{...register(`options.${index}.name`, { required: true })}
														label={`Name ${(index + 1)}`}
														fullWidth
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														required
														{...register(`options.${index}.label`, { required: true })}
														label={`Label ${(index + 1)}`}
														fullWidth
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														required
														{...register(`options.${index}.value`, { required: true, pattern: { value: /^[+-]?([0-9]*[.])?[0-9]*$/i, message: errorMessage}})}
														error={errors.options?.[index]?.value ? true : false}
														label={`Value ${(index + 1)}`}
														fullWidth
													/>
												</Grid>
											</Grid>
											<Grid item alignSelf='center' xs={2}>
												<IconButton id={index} onClick={() => remove(index)}>
													<DeleteForeverIcon />
												</IconButton>
											</Grid>
										</Grid>
									)}
								</Grid>
							</Box>
						</Grid>
						{(errors.options && (watch('options').length >= 2)) &&
							<Grid item textAlign='center'>
								<Alert severity='error'>
									{errorMessage}
								</Alert>
							</Grid>
						}
						{(watch('options').length < 2) &&
							<Grid item xs={12} textAlign='center'>
								<Typography>Add at least 2 options</Typography>
							</Grid>
						}
						<Grid item xs={12}>
							<Button
								variant='contained'
								fullWidth
								onClick={() => append({})}
							>
								Add Option
							</Button>
						</Grid>
					</>
					}
					{(watch('inputType') === inputTypes.inputField) &&
					<>
						<Grid container item xs={12} spacing={2}>
							<Grid item xs={6}>
								<FormControl fullWidth>
									<InputLabel>Required</InputLabel>
									<Select
										{...register('required')}
										label='Required'
										value={watch('required')}
										onClose={() => {
											setTimeout(() => {
												document.activeElement.blur();
											}, 0);
										}}
									>
										<MenuItem value={true}>Required</MenuItem>
										<MenuItem value={false}>Not Required</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl fullWidth>
									<InputLabel>Data Type</InputLabel>
									<Select
										{...register('dataType')}
										label='Data Type'
										value={watch('dataType')}
										onClose={() => {
											setTimeout(() => {
												document.activeElement.blur();
											}, 0);
										}}
									>
										<MenuItem value={'Number'}>Number</MenuItem>
										<MenuItem value={'String'}>String</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<TextField
								{...register('label')}
								error={errors.label ? true : false}
								helperText={errors.label?.message}
								variant='outlined'
								fullWidth
								label='Label'
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								{...register('defaultValue')}
								error={errors.defaultValue ? true : false}
								helperText={errors.defaultValue?.message}
								variant='outlined'
								fullWidth
								label='Default Value'
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								{...register('errorMessage')}
								error={errors.errorMessage ? true : false}
								helperText={errors.errorMessage?.message}
								variant='outlined'
								fullWidth
								label='Error Message'
								multiline
								maxRows={3}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								{...register('validationRegex')}
								error={errors.validationRegex ? true : false}
								helperText={errors.validationRegex?.message}
								variant='outlined'
								fullWidth
								label='Validation Regex'
								multiline
								maxRows={3}
							/>
						</Grid>
					</>
					}
					<Grid item xs={12}>
						<Button
							fullWidth
							variant='contained'
							type='submit'
						>Save</Button>
					</Grid>
				</Grid>
			</Box>
		</form>
	);
};
