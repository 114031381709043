import React, {
	useState,
	useEffect,
	createContext,
	useContext
} from 'react';
import { useParams } from 'react-router';
import { useAccessToken } from './useAccessToken';

const CalculatorContext = createContext();

export const useCalculatorContext = () => {
	return useContext(CalculatorContext);
};

export const CalculatorProvider = ({ children }) => {
	const [ calculator, setCalculator ] = useState({});
	const [ preview, setPreview ] = useState({});
	const [ calculators, setCalculators ] = useState([]);
	const [ update, setUpdate ] = useState(false);
	const [ currentParameter, setCurrentParameter ] = useState([]);
	const [ loading, setLoading ] = useState(true);
	const calculatorId = useParams();
	const accessToken = useAccessToken();

	const createCalculation = (calculationBody) => {
		if(!accessToken) return;
		let response = fetch(`${process.env.REACT_APP_BASE_ADDRESS}/Expressions`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(calculationBody)
		})
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(`${response.status} ${response.statusText}`);
				}
			})
			.then((response) => {
				return response;
			})
			.catch(error => console.error(error));
		return response;
	};

	const editCalculation = (calculationBody) => {
		if(!accessToken) return;
		let response = fetch(`${process.env.REACT_APP_BASE_ADDRESS}/Expressions/${calculationBody.id}`, {
			method: 'PUT',
			headers: { 
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(calculationBody)
		})
			.then((response) => {return response;} )
			.catch(error => console.error(error));
		return response;
	};

	const deleteCalculation = (calculatorId, expressionId) => {
		if(!accessToken) return;
		let response = fetch(`${process.env.REACT_APP_BASE_ADDRESS}/Expressions/${calculatorId}/${expressionId}`, {
			method: 'DELETE',
			headers: { 
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify({calculatorId, expressionId})
		})
			.then((response) => {return response;} )
			.catch(error => console.error(error));
		return response;
	};

	const deleteParameter = (parameterId) => {
		if(!accessToken) return;
		let response = fetch(`${process.env.REACT_APP_BASE_ADDRESS}/Parameter/${parameterId}`, {
			method: 'DELETE',
			headers: { 
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({parameterId})
		})
			.then((response) => {
				if (response.ok) {
					return response;
				} else {
					throw new Error(`${response.status} ${response.statusText}`);
				}
			})
			.then((response) => {
				return response;
			})
			.catch(error => console.error(error));
		return response;
	};

	const editParameter = (parameterBody) => {
		if(!accessToken) return;
		let result =  fetch(`${process.env.REACT_APP_BASE_ADDRESS}/Parameter/${parameterBody.id}`, {
			method: 'PUT',
			headers: { 
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(parameterBody)
		})
			.then((response) => {
				if (response.ok) {
					return response;
				} else {
					throw new Error(`${response.status} ${response.statusText}`);
				}
			})
			.then((response) => {return response;})
			.catch(error => console.error(error));
		return result;
	};

	const createParameter = (parameterBody) => {
		if(!accessToken) return;
		let result = fetch(`${process.env.REACT_APP_BASE_ADDRESS}/Parameter`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(parameterBody)
		})
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(`${response.status} ${response.statusText}`);
				}
			})
			.then((response) => {return response;})
			.catch(error => {
				console.error(error);
			});
		return result;
	};

	const createCalculator = (calculatorBody) => {
		if(!accessToken) return;
		let result = fetch(`${process.env.REACT_APP_BASE_ADDRESS}/ClientCalculators`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(calculatorBody)
		})
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(`${response.status} ${response.statusText}`);
				}
			})
			.then((response) => {return response;})
			.catch(error => console.error(error));
		return result;
	};

	const deleteCalculator = (calculatorId) => {
		if(!accessToken) return;
		let result = fetch(`${process.env.REACT_APP_BASE_ADDRESS}/ClientCalculators/${calculatorId}`, {
			method: 'DELETE',
			headers: { 
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify({calculatorId})
		})
			.then((response) => {
				if (response.ok) {
					return response;
				} else {
					throw new Error(`${response.status} ${response.statusText}`);
				}
			})
			.then((response) => {return response;})
			.catch(error => console.error(error));
		return result;
	};

	const editCalculator = (calculatorBody) => {
		if(!accessToken) return;
		let result = fetch(`${process.env.REACT_APP_BASE_ADDRESS}/ClientCalculators/${calculatorBody.id}`, {
			method: 'PUT',
			headers: { 
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(calculatorBody)
		})
			.then((response) => {
				if (response.ok) {
					return response;
				} else {
					throw new Error(`${response.status} ${response.statusText}`);
				}
			})
			.then((response) => {return response;})
			.catch(error => console.error(error));
		return result;
	};

	const getParameter = (parameterId) => {
		if(!accessToken) return;
		let result = fetch(`${process.env.REACT_APP_BASE_ADDRESS}/Parameter/${parameterId}`,{
			headers: { 
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		})
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(`${response.status} ${response.statusText}`);
				}
			})
			.then((result) => {
				setCurrentParameter(result);
			})
			.catch(error => console.error(error));
		return result;
	};

	const createCondition = (conditionBody) => {
		let result = fetch(`${process.env.REACT_APP_BASE_ADDRESS}/Condition`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(conditionBody)
		})
			.then((response) => {
				if (response.ok) {
					return response;
				} else {
					throw new Error(`${response.status} ${response.statusText}`);
				}
			})
			.catch(error => console.error(error));
		return result;
	};

	const editCondition = (conditionBody) => {
		let result = fetch(`${process.env.REACT_APP_BASE_ADDRESS}/Condition/${conditionBody.conditionId}`, {
			method: 'PUT',
			headers: { 
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(conditionBody)
		})
			.then((response) => {
				if (response.ok) {
					return response;
				} else {
					throw new Error(`${response.status} ${response.statusText}`);
				}
			})
			.then((response) => {return response;})
			.catch(error => {
				console.error(error);
			});
		return result;
	};

	const deleteCondition = (conditionBody) => {
		let result = fetch(`${process.env.REACT_APP_BASE_ADDRESS}/Condition/${conditionBody.calculatorId}/${conditionBody.conditionId}`, {
			method: 'DELETE',
			headers: { 
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(conditionBody)
		})
			.then((response) => {
				if (response.ok) {
					return response;
				} else {
					throw new Error(`${response.status} ${response.statusText}`);
				}
			})
			.then((response) => {return response;})
			.catch(error => {
				console.error(error);
			});
		return result;
	};

	const calculateExpression = (calculateExpressionBody) => {
		if(!accessToken) return;
		let result = fetch(`${process.env.REACT_APP_BASE_ADDRESS}/Calculator/CalculateExpression`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(calculateExpressionBody)
		})
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(`${response.status} ${response.statusText}`);
				}
			})
			.then((response) => {return response;})
			.catch(error => {
				console.error(error);
			});
		return result;
	};

	const calculateAll = (calculateAllBody) => {
		if(!accessToken) return;
		let result = fetch(`${process.env.REACT_APP_BASE_ADDRESS}/Calculator/CalculateAll`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(calculateAllBody)
		})
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(`${response.status} ${response.statusText}`);
				}
			})
			.then((response) => {return response;})
			.catch(error => {
				console.error(error);
			});
		return result;
	};

	const calculateStep = (endpoint, calculateStepBody) => {
		if(!accessToken) return;
		let result = fetch(`${process.env.REACT_APP_BASE_ADDRESS}/${endpoint}`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(calculateStepBody)
		})
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(`${response.status} ${response.statusText}`);
				}
			})
			.then((response) => {return response;})
			.catch(error => {
				console.error(error);
			});
		return result;
	};

	useEffect(() => {
		if(!accessToken || !calculatorId.id) return;
		fetch(`${process.env.REACT_APP_BASE_ADDRESS}/CalculationEditor/${calculatorId.id}`,{
			headers: { 
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		})
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(`${response.status} ${response.statusText}`);
				}
			})			
			.then((response) => {
				setCalculator(response);
				setLoading(false);
			})
			.catch(error => console.error(error));
	}, [accessToken, update]);

	useEffect(() => {
		if(!accessToken) return;
		fetch(`${process.env.REACT_APP_BASE_ADDRESS}/ClientCalculators/GetList`,
			{
				headers: { 
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			})
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					throw new Error(`${response.status} ${response.statusText}`);
				}
			})
			.then((result) => {
				setCalculators(result.calculators);
				setLoading(false);
			})
			.catch(error => {
				console.error(error);
				setLoading(false);
			});
	}, [accessToken, update]);

	useEffect(() => {
		if(!accessToken || !calculatorId.id) return;
		fetch(`${process.env.REACT_APP_BASE_ADDRESS}/Preview/${calculatorId.id}`,
			{
				headers: { 
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			})
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					throw new Error(`${response.status} ${response.statusText}`);
				}
			})
			.then((result) => {
				setPreview(result);
				setLoading(false);
			})
			.catch(error => {
				console.error(error);
				setLoading(false);
			});
	}, [accessToken, update]);

	return (
		<CalculatorContext.Provider value={{
			createParameter,
			editParameter,
			deleteCalculation,
			deleteParameter,
			createCalculator,
			deleteCalculator,
			editCalculator,
			editCalculation,
			createCalculation,
			calculator,
			calculators,
			getParameter,
			currentParameter,
			setUpdate,
			update,
			loading,
			preview,
			createCondition,
			editCondition,
			deleteCondition,
			calculateExpression,
			calculateAll,
			calculateStep
		}}>
			{children}
		</CalculatorContext.Provider>
	);
};
