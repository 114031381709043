import React, { useEffect, useState } from 'react';
import getCaretCoordinates from 'textarea-caret';
import { TextareaAutosize } from '@mui/base';
import './AutoComplete.css';
import {
	getActiveToken,
	replaceAt,
	isValidParameterName,
} from '../../../utilities/autoCompleteUtilities';
import { useOutsideClick } from '../../../utilities/useOutsideClick';
import { useCalculatorContext } from '../../../utilities';
import { AutoCompleteItem } from './AutoCompleteItem';

export const AutoCompleteInput = ({ error, onChange, value, label, rows }) => {
	const [state, setState] = useState(() => ({
		collections: [],
		isOpen: false,
		activeToken: '',
		query: value,
	}));
	const { calculator } = useCalculatorContext();
	const { parameterList } = calculator;

	const handleClickOutside = () => {
		setState({ ...state, isOpen: false });
	};

	const inputRef = React.useRef(null);
	const ref = useOutsideClick(handleClickOutside);
	const autocompleteItemsRef = React.useRef(null);

	const { top, left } = inputRef.current
		? getCaretCoordinates(inputRef.current, inputRef.current?.selectionEnd)
		: { top: 0, left: 0 };

	useEffect(() => {
		if (!inputRef.current) return;
		onChange(inputRef.current.value);
	}, [state.query]);

	const handleChange = () => {
		if (!parameterList) return;
		setTimeout(() => {
			const query = inputRef.current.value;
			const cursorPosition = inputRef.current?.selectionEnd || 0;
			setTokenSpacing(cursorPosition, query);
			const activeToken = getActiveToken(query, cursorPosition);
			if (activeToken?.word && isValidParameterName(activeToken?.word)) {
				const collections = parameterList.filter((parameter) => {
					const dataName = parameter.dataName.toLowerCase();
					if (query.length > 0) {
						return dataName.includes(activeToken.word.slice(1).toLowerCase());
					}
					return false;
				});
				setState({
					collections: collections.length
						? [{ items: collections, activeToken: activeToken.word.slice(1) }]
						: null,
					activeToken,
					isOpen: collections.length ? true : false,
					query: query,
				});
				selectFirstItem();
			} else {
				setState({
					collections: null,
					isOpen: false,
					activeToken: null,
					query: query,
				});
			}
		}, 0);
	};

	const onInputNavigate = (e) => {
		if (['ArrowRight', 'ArrowLeft'].includes(e.key)) {
			handleChange();
		} else if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
			e.preventDefault();
			selectNextItem(e.key);
		} else if (e.key === 'Enter') {
			if (!autocompleteItemsRef.current) return;
			const currentItem = autocompleteItemsRef.current.querySelector(
				'[aria-selected="true"]'
			);
			if (currentItem) {
				e.preventDefault();
				currentItem.click();
			}
		}
		
	};

	const handleSelect = (item) => {
		const query = inputRef.current.value;
		const cursorPosition = inputRef.current?.selectionEnd || 0;
		const activeToken = getActiveToken(query, cursorPosition);
		if (activeToken?.word && isValidParameterName(activeToken?.word)) {
			const [index] = state.activeToken.range;
			let spacing = '';
			if (query[cursorPosition] !== ' ') spacing = ' ';
			const replacement = `@${item.dataName + spacing}`;
			const newQuery = replaceAt(
				query,
				replacement,
				index,
				activeToken.word.length
			);

			inputRef.current.value = newQuery;
			inputRef.current.selectionEnd = index + replacement.length;
			inputRef.current.focus();
			setState({ collections: null, isOpen: false, query: newQuery });
		}
	};

	const selectFirstItem = () => {
		setTimeout(() => {
			if (!autocompleteItemsRef.current) return;
			const firstItem = autocompleteItemsRef.current.querySelector('button');
			const currentItem = autocompleteItemsRef.current.querySelector(
				'[aria-selected="true"]'
			);
			currentItem && currentItem.setAttribute('aria-selected', false);
			firstItem.setAttribute('aria-selected', true);
		}, 0);
	};

	const selectNextItem = (orientation) => {
		if (!state.collections) return;
		const items = autocompleteItemsRef.current.querySelectorAll('button');
		let currentItem =
			autocompleteItemsRef.current.querySelector('[aria-selected="true"]') ||
			items[0];

		const itemIndex = [].indexOf.call(items, currentItem);
		if (orientation === 'ArrowUp') {
			if (items[itemIndex - 1]) {
				items[itemIndex - 1].setAttribute('aria-selected', true);
				currentItem.setAttribute('aria-selected', false);
			} else {
				currentItem.setAttribute('aria-selected', false);
				items[items.length - 1].setAttribute('aria-selected', true);
			}
		} else if (orientation === 'ArrowDown') {
			if (items[itemIndex + 1]) {
				currentItem.setAttribute('aria-selected', false);
				items[itemIndex + 1].setAttribute('aria-selected', true);
			} else {
				currentItem.setAttribute('aria-selected', false);
				items[0].setAttribute('aria-selected', true);
			}
		}
	};

	const setTokenSpacing = (cursorPosition, query) => {
		if (cursorPosition !== 0 && query[cursorPosition] === '@') {
			if (cursorPosition - 1 >= 0 && query[cursorPosition - 1] !== ' ') {
				const newQuery = replaceAt(
					query,
					' @',
					cursorPosition ,
					1
				);
				inputRef.current.value = newQuery;
				inputRef.current.selectionEnd = cursorPosition;
			}
		} else if (cursorPosition !== 0 && query[cursorPosition - 1] === '@') {
			if (cursorPosition - 2 >= 0 && query[cursorPosition - 2] !== ' ') {
				const newQuery = replaceAt(
					query,
					' @',
					cursorPosition - 1 ,
					1
				);
				inputRef.current.value = newQuery;
				inputRef.current.selectionEnd = cursorPosition  + 1;
			}
		}
	};

	return (
		<div className='AutoComplete' ref={ref}>
			<TextareaAutosize
				className={`AutoComplete_textBox ${error.length > 0 ? 'hasError' : ''}`}
				placeholder='Use @ to trigger typeahead'
				error={'true'}
				label={label}
				minRows={rows}
				defaultValue={value}
				ref={inputRef}
				onKeyDown={(event) => {
					onInputNavigate(event);
				}}
				onChange={handleChange}
				onClick={handleChange}
			/>

			{error && error.length > 0 && (
				<>
					{error.map((err, index) => (
						<p key={`error-${index}`} className='AutoComplete_error'>
							{err}
						</p>
					))}
				</>
			)}
			<div
				className={`AutoComplete_panel ${
					state.isOpen ? 'AutoComplete_panel___open' : ''
				}`}
				style={{
					top: `${top + 30}px`,
					left: `${left - 100 >= 0 ? left - 100 : 0}px`,
				}}
			>
				{state.isOpen &&
					state.collections.map(({ items, index }) => {
						if (items.lenght <= 0) return;
						return (
							<ul
								key={`source-${index}`}
								className='AutoComplete_items'
								ref={autocompleteItemsRef}
							>
								{items.map((item) => {
									return (
										<li key={`item-${item.id}`}>
											<AutoCompleteItem
												handleSelect={handleSelect}
												hit={item}
												activeToken={state.activeToken}
											/>
										</li>
									);
								})}
							</ul>
						);
					})}
			</div>
		</div>
	);
};
