import React from 'react';
import { highlight } from '../../../utilities/autoCompleteUtilities';
export const AutoCompleteItem = ({ hit, handleSelect, activeToken }) => {
	const query = activeToken?.word.slice(1);
	if (!query) return <></>;
	return (
		<button type="button" className='AutoComplete_item' onClick={() => handleSelect(hit)}>
			<div>
				<div className='AutoComplete_itemName'>
					<span dangerouslySetInnerHTML={highlight(hit.name, query)}></span>
				</div>
				<div className='AutoComplete_itemTag'>
					@
					<span dangerouslySetInnerHTML={highlight(hit.dataName, query)}></span>
				</div>
			</div>
		</button>
	);
};