import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useModalContext } from '../../utilities';
import { CreateParameterForm } from './';

export const CreateParameterButton = () => {
	const { showModal, setModalChildren } = useModalContext();

	const handleOpen = () => {
		showModal();
		setModalChildren(<CreateParameterForm />);
	};

	return (	
		<Button
			variant='contained'
			endIcon={<AddIcon />}
			onClick={handleOpen}
		>
			New
		</Button>
	);
};
