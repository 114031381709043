export const getActiveToken = (input, cursorPosition) => {
	const tokenizedQuery = input.split(/[\s\n]/).reduce((acc, word, index) => {
		const previous = acc[index - 1];
		const start = index === 0 ? index : previous.range[1] + 1;
		const end = start + word.length;
  
		return acc.concat([{ word, range: [start, end] }]);
	}, []);
  
	if (cursorPosition === undefined) {
		return undefined;
	}

	const activeToken = tokenizedQuery.find(
		({ range }) => {
			return range[0] < cursorPosition && range[1] >= cursorPosition;}
	);
  
	return activeToken;
};


export const replaceAt = (str, replacement, index, length = 0) => {
	const prefix = str.substr(0, index);
	const suffix = str.substr(index + length);

	return prefix + replacement + suffix;
};

export const isValidParameterName = (parameter) => {
	return /^@\w{1,30}$/.test(parameter);
};


export const highlight = (hit, query)=> {
	if (hit) return {__html: hit.replace(new RegExp(query, 'gi'), match => `<mark>${match}</mark>`)};
	return {__html: hit.attribute};
};
