import React from 'react';
import { Grid } from '@mui/material';
import {
	CalculatorProvider,
	SnackbarProvider
} from '../utilities';
import { TestCalculationList } from '../components/testcalculator';

export const TestCalculatorPage = () => {

	return (
		<CalculatorProvider>
			<SnackbarProvider>
				<Grid container justifyContent='space-evenly' sx={{	marginLeft: 'auto',
					marginRight: 'auto',
					maxWidth:'1280px',}}>
					<Grid>
						<TestCalculationList />
					</Grid>
				</Grid>
			</SnackbarProvider>
		</CalculatorProvider>
	);
};
