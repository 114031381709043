import React from 'react';
import {
	useCalculatorContext,
	useModalContext
} from '../../utilities';
import {
	MyCalculatorBox,
	CreateCalculatorForm
} from './';
import {
	Grid,
	Button
} from '@mui/material';
import { Stack } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Loading } from '../Loading';

export const MyCalculatorsList = () => {
	const { calculators, loading } = useCalculatorContext();
	const { showModal, setModalChildren } = useModalContext();
	const handleNew = () => {
		showModal();
		setModalChildren(
			<CreateCalculatorForm />
		);
	};

	return (
		<>
			{loading && (
				<Loading text="Loading..."/>
			)}
			{(!loading && (calculators.length !== 0)) &&
			<Grid
				container
				rowSpacing={2}
				direction='column'
			>
				<Grid
					item
					alignSelf='flex-end'
					mt={2}
				>
					<Button
						variant='contained'
						component={RouterLink}
						onClick={handleNew}
					>
						New{<AddIcon />}
					</Button>
				</Grid>
				<Grid
					item
				>
					<Stack
						spacing={2}
					>
						{calculators?.map(calculator =>
							<MyCalculatorBox
								key={calculator.id}
								calculatorName={calculator.name}
								calculatorId={calculator.id}
							/>
						)}
					</Stack>
				</Grid>
			</Grid>}
			{(!loading && (calculators.length === 0)) &&
				<Grid
					container
					rowSpacing={2}
					direction='column'
				>
					<Grid
						item
						alignSelf='flex-end'
						mt={2}
					>
						<Button
							variant='contained'
							component={RouterLink}
							onClick={handleNew}
						>
							New{<AddIcon />}
						</Button>
					</Grid>
				</Grid>
			}
		</>
	);
};
