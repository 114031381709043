import React from 'react';
import {
	Typography,
	Grid,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@mui/material';
import { EditParameterButton, DeleteParameterButton } from './';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const ParameterAccordion = ({ parameters, label }) => {
	return (
		<Accordion
			disableGutters
			rounded={'false'}
			sx={{ before: { opacity: 1 }, boxShadow: 'none' }}
			TransitionProps={{ unmountOnExit: true }}
		>
			<AccordionSummary
				sx={{
					padding: 0,
					borderBottom: '1px solid lightgray',
				}}
				expandIcon={<ExpandMoreIcon />}
			>
				<Typography variant='h5'>{label}</Typography>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					padding: 0,
					paddingTop: 2,
					borderBottom: '1px solid lightgray',
				}}
			>
				{parameters.map((parameter) => (
					<Grid
						item
						xs={12}
						mb={2}
						container
						key={parameter.id}
						justifyContent='space-between'
					>
						<Grid item xs={10}>
							<EditParameterButton parameter={parameter} />
						</Grid>
						<Grid item xs={2} display='flex' justifyContent='flex-end'>
							<DeleteParameterButton parameter={parameter} />
						</Grid>
					</Grid>
				))}
			</AccordionDetails>
		</Accordion>
	);
};
