import React, { useState, useEffect } from 'react';
import { useCalculatorContext } from '../../utilities';
import { FormStep } from '.';
import {
	Grid, Typography,
	Button
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Loading } from '../Loading';
import {
	useForm,
	FormProvider
} from 'react-hook-form';

export const PreviewCalculator = () => {
	const { calculator, loading, preview } = useCalculatorContext();

	const [ responseVariables, setResponseVariables] = useState({});
	const [ showResults, setShowResults ] = useState(false);
	const [ currentFormSteps, setCurrentFormSteps ] = useState([]);
	const [ disabledSteps, setDisabledSteps ] = useState([]);
	const [ currentStep, setCurrentStep ] = useState(0);
	const editPath = `/editCalculator/${calculator.calculatorId}`;
	const { formState: {isValid, isDirty, errors}, ...methods } = useForm({
		mode: 'all',
		defaultValues: {
			variables: {}
		}
	});

	useEffect(() => {
		let steps = [];
		for (let i = 0; i < preview?.formSteps?.length; i++) {
			let step = preview.formSteps[i];
			if (step.calculateStep) {
				steps = [...steps, step.order];
				setCurrentFormSteps([...currentFormSteps, ...steps]);
				setCurrentStep(step.order);
				break;
			}
			steps = [...steps, step.order];
		}
	}, [preview]);
	
	
	return (
		<>
			{loading && (
				<Loading text="Loading..."/>
			)}
			{!loading && preview.formSteps && (
				<>
					<FormProvider {...{formState: {isValid, isDirty, errors}, ...methods}}>
						<Grid container item xs={12} justifyContent='space-between' marginTop={2}>
							<Grid>
								<Typography variant='h5'><b>Preview:</b> {preview.calculatorName}</Typography>
							</Grid>
							<Grid item>
								<Button
									variant='contained'
									component={RouterLink}
									to={editPath}
								>Edit Calculator</Button>
							</Grid>
						</Grid>
						<Grid>
							{
								preview.formSteps.map((step, index) => {
									if (currentFormSteps.includes(index)) {
										return (<FormStep
											key={index}
											step={step}
											index={index}
											currentFormSteps={currentFormSteps}
											setCurrentFormSteps={setCurrentFormSteps}
											currentStep={currentStep}
											setCurrentStep={setCurrentStep}
											setResponseVariables={setResponseVariables}
											responseVariables={responseVariables}
											showResults={showResults}
											setShowResults={setShowResults}
											disabledSteps={disabledSteps}
											setDisabledSteps={setDisabledSteps}
										/>);
									}
								})
							}
						</Grid>
					</FormProvider>
				</>
			)}
		</>
	);
};
