import React  from 'react';
import {
	Routes,
	Route
} from 'react-router-dom';
import { Header } from './components/header';
import {
	EditCalculatorPage,
	MyCalculatorsPage,
	TestCalculatorPage,
	PreviewCalculatorPage,
	HomePage,
	NoPage
} from './pages';
import { RequireAuth } from './components/RequireAuth';
import './App.css';

function App() {
	return (
		<>
			<Header />
			<Routes>
				<Route exact path="/" element={<HomePage />} />
				<Route exact path="/editCalculator/:id" element={
					<RequireAuth>
						<EditCalculatorPage />
					</RequireAuth>
				} />
				<Route exact path="/testCalculator/:id" element={
					<RequireAuth>
						<TestCalculatorPage/>
					</RequireAuth>
				} />
				<Route exact path="/previewCalculator/:id" element={
					<RequireAuth>
						<PreviewCalculatorPage/>
					</RequireAuth>
				} />
				<Route exact path="/editCalculator" element={
					<RequireAuth>
						<EditCalculatorPage/>
					</RequireAuth>
				} />
				<Route exact path="/myCalculators" element={
					<RequireAuth>
						<MyCalculatorsPage />
					</RequireAuth>
				} />
				<Route exact path="*" element={<NoPage />} />
			</Routes>
		</>
	);
}
export default App;
