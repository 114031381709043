import React from 'react';
import {
	Button,
	TextField,
	Grid
} from '@mui/material';
import { useForm } from 'react-hook-form';
import {
	useModalContext,
	useCalculatorContext,
	useSnackbarContext
} from '../../utilities';
import camelCase from 'camelcase';

export const CreateCalculationForm = ( params ) => {
	const { closeModal } = useModalContext();
	const { showSnackbar } = useSnackbarContext();
	const { calculator, update, setUpdate, createCalculation } = useCalculatorContext();
	const { register, handleSubmit, getValues, watch } = useForm({
		shouldUnregister: true,
		defaultValues: {
			calculatorId: params.calculatorId,
			formula: 'formula',
			formulaString:'formula',
			order: calculator.expressionList.length + 1,
			variables: null,
			result:{
				roundResult: false,
				roundPrecision: 0
			}
		}
	});
	const dataName = watch('name');
	
	const onSubmit = async  () => {
		let calculationBody = getValues();
		calculationBody.dataName = camelCase(dataName);
		let response = await createCalculation(calculationBody);
		if (response && response.id) {
			showSnackbar('Calculation created', 'success');
			setUpdate(!update);
		} else {
			showSnackbar('Error - Calculation was NOT created', 'error');
		}
		closeModal();
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={2} justifyContent='flex-end'>
				<Grid item xs={12}>
					<TextField
						required
						{...register('name', { required: true })}
						fullWidth
						label='Calculation Name'
						variant='standard'
					/>
				</Grid>
				<Grid item>
					<Button variant='contained' type='submit'>Create</Button>
				</Grid>
			</Grid>
		</form>
	);
};
