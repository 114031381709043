import React from 'react';
import {
	TextField
} from '@mui/material';
import { useFormContext } from 'react-hook-form';

export const ConditionValue = ({ index }) => {
	const { register, watch, formState: { errors } } = useFormContext();
	
	return (
		<TextField 
			fullWidth
			{...register(`rules.${index}.conditionValue`, { required: true, pattern: /^[+-]?([0-9]*[.])?[0-9]*$/i })}
			value={watch(`rules.${index}.conditionValue`) || ''}
			error={errors?.rules?.[index]?.conditionValue ? true : false}
			variant='outlined'
			label='Value'
		/>
	);
};