import React, {
	useState,
	createContext,
	useContext
} from 'react';
import {
	Modal,
	Box,
	Grid,
	IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ModalContext = createContext();

export const useModalContext = () => {
	return useContext(ModalContext);
};

export const ModalProvider = ({ children }) => {
	const [modalChildren, setModalChildren]= useState(<></>);
	const [openModal, setOpenModal] = useState(false);
	const [modalWidth, setModalWidth] = useState('sm');

	const templateWidths = {
		sm:'20rem',
		md:'40rem'
	};

	const closeModal = () => {
		setOpenModal(false);
	};
	const showModal = () => {
		setOpenModal(true);
	};
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: `${templateWidths[modalWidth]}`,
		maxWidth: 'calc(100% - 6rem)',
		maxHeight: 'calc(100% - 6rem)',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		overflow: 'hidden',
		overflowY: 'auto',
		p: 4
	};	

	return (
		<>
			<ModalContext.Provider value={{showModal, closeModal, setModalChildren, openModal, modalWidth, setModalWidth}}>
				{children}
				<Modal
					open={openModal}
					onClose={closeModal}
				>
					<Box sx={style}>
						<Grid item container justifyContent='flex-end'>
							<IconButton onClick={closeModal}><CloseIcon /></IconButton>
						</Grid>
						{modalChildren}
					</Box>
				</Modal>
			</ModalContext.Provider>
		</>
	);
};