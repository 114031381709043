import React, {
	useState,
	createContext,
	useContext
} from 'react';
import {
	Alert,
	Snackbar
} from '@mui/material';

const SnackbarContext = createContext();

export const useSnackbarContext = () => {
	return useContext(SnackbarContext);
};

export const SnackbarProvider = ({ children }) => {
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState('');
	const [severity, setSeverity] = useState('success');
	const handleClose = () => {
		setOpen(false);
	};
	const showSnackbar = (message, severity) => {
		setMessage(message);
		setSeverity(severity);
		setOpen(true);
	};

	return (
		<>
			<Snackbar
				open={open}
				autoHideDuration={10000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			>
				<Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
					{message}
				</Alert>
			</Snackbar>
			<SnackbarContext.Provider value={{ showSnackbar }}>
				{children}
			</SnackbarContext.Provider>
		</>
	);
};