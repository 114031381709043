import React from 'react';
import {
	Grid
} from '@mui/material';
import { useCalculatorContext } from '../../utilities';
import {
	Condition,
	CreateConditionButton
} from '.';

export const ConditionList = ({ expressionId, index, expression }) => {
	const { calculator, loading } = useCalculatorContext();

	return (
		<Grid container flexDirection='column' spacing={2} marginTop={2}>
			{(!loading && calculator.expressionList[index] ) && calculator?.expressionList[index]?.conditionList.map((condition, index) => {
				if (expressionId !== condition.expressionId) {
					return;
				} else {
					return (
						<Grid container item key={condition.conditionId} flexDirection='column' marginTop={0}>
							<Condition condition={condition} index={index} expression={expression} expressionId={expressionId}/>
						</Grid>
					);
				}
			})}
			<Grid item alignSelf='flex-end'>
				<CreateConditionButton expressionId={expressionId}/>
			</Grid>
		</Grid>
	);
};