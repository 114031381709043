import React from 'react';
import { TestCalculation } from '.';
import { useCalculatorContext } from '../../utilities';
import {
	Grid,
	Typography,
	Button
} from '@mui/material';
import {
	useForm,
	FormProvider
} from 'react-hook-form';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Loading } from '../Loading';

export const TestCalculationList = () => {
	const { calculator, loading } = useCalculatorContext();
	const [ showLimit, setShowLimit ] = useState('0');
	const [ result, setResult ] = useState({});
	const { formState: {isValid, isDirty, errors}, ...methods } = useForm({
		mode: 'all',
		shouldUnregister: true,
		defaultValues: {
			calculation: {}
		}
	});
	const editPath = `/editCalculator/${calculator.calculatorId}`;

	const showFields = (index) => {
		setShowLimit(index);
		setResult({});
		methods.reset(
			{
				calculation: methods.watch('calculation')
			},
			{
				keepDirty: true,
				keepErrors: true,
				keepIsValid: true
			}
		);
	};

	return (
		<>
			{loading && (
				<Loading text="Loading..."/>
			)}
			{!loading && (
				<>
					<Grid container item xs={12} justifyContent='space-between' marginTop={2}>
						<Grid item>
							<Typography variant='h4'>
								<b>Test:</b>{calculator.calculatorName}
							</Typography>
						</Grid>
						<Grid item>
							<Button
								variant='contained'
								component={RouterLink}
								to={editPath}
							>Edit Calculator</Button>
						</Grid>
					</Grid>
					<Grid container item xs={12} flexDirection='column' rowGap={2} marginTop={2}>
						<FormProvider {...{formState: {isValid, isDirty, errors}, ...methods}}>
							{calculator?.expressionList?.map((expression, index) =>
								<Grid item key={expression.id}>
									<TestCalculation
										expression={expression}
										parameterList={calculator.parameterList}
										index={index}
										showFields={showFields}
										showLimit={showLimit}
										result={result}
										setResult={setResult}
									/>
								</Grid>
							)}
						</FormProvider>
					</Grid>
				</>
			)}
		</>
	);
};
