import React from 'react';
import { Button } from '@mui/material';
import { CreateConditionForm } from './';
import {
	useModalContext
} from '../../utilities';

export const CreateConditionButton = ({expressionId}) => {
	const { showModal, setModalChildren } = useModalContext();
	const handleCreate = async () => {
		showModal();
		setModalChildren( <CreateConditionForm expressionId={expressionId}/> );
	};
	
	return (
		<Button
			variant='contained'
			fullWidth
			onClick={handleCreate}
		>
			Add Condition
		</Button>
	);
};
