import React, { useState } from 'react';
import {
	useCalculatorContext,
	useSnackbarContext,
	useModalContext
} from '../../utilities';
import {
	Grid,
	Box,
	Button,
	TextField,
	Stack
} from '@mui/material';

import { Loading } from '..';
import {
	Calculation,
	CreateCalculationForm,
	DeleteCalculatorPrompt
} from '.';
import { useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

export const CalculationList = () => {
	const { showModal, setModalChildren } = useModalContext();
	const { calculator, update, setUpdate, loading, editCalculator } = useCalculatorContext();
	const { showSnackbar } = useSnackbarContext();
	const [ name, setName ] = useState(calculator.calculatorName);
	const params = useParams();
	const testPath = `/testCalculator/${params.id}`;
	const previewPath = `/previewCalculator/${params.id}`;
	
	const createCalculation = () => {
		showModal();
		setModalChildren(<CreateCalculationForm calculatorId={params.id}/>);
	};

	const deleteCalculator = () => {
		showModal();
		setModalChildren(<DeleteCalculatorPrompt calculator={calculator}/>);
	};

	const handleChange = (e) => {
		setName(e.target.value);
	};

	const handleEdit = async () => {
		let calculatorBody = {};
		calculatorBody.id = calculator.calculatorId;
		calculatorBody.name = name ? name : calculator.calculatorName;
		let response = await editCalculator(calculatorBody);
		if (response && response.status === 204) {
			showSnackbar('Calculator saved', 'success');
			setUpdate(!update);
		} else {
			showSnackbar('Error - Calculator NOT saved', 'error');
		}
	};

	return (
		<Box sx={{
			px: 2,
			pt: 5,
		}}>
			{loading && (
				<Loading text="Loading..."/>
			)}
			{!loading && calculator.expressionList && 
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Grid item container flexDirection='row' xs={12} justifyContent='space-between'>
						<Grid item xs={6}>
							<TextField
								defaultValue={calculator.calculatorName}
								fullWidth
								variant='standard'
								inputProps={{style: {fontSize: 30}}}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item>
							<Button variant='contained' onClick={createCalculation}>Add Calculation</Button>
						</Grid>
					</Grid>
				</Grid>
				{calculator.expressionList.map((expression, index) =>
					<Grid item xs={12} key={index}>
						<Calculation
							index={index}
							{...expression}
						/>
					</Grid>
				)}
				<Grid container item xs={12} justifyContent='space-between' spacing={2}>
					<Grid item>
						<Button variant='contained' color='error' onClick={deleteCalculator}>Delete Calculator</Button>
					</Grid>
					<Grid item>
						<Stack spacing={2}>
							<Button variant='contained' onClick={handleEdit}>Save Calculator</Button>
							<Button 
								variant='contained'
								component={RouterLink}
								to={previewPath}
							>
								Preview Calculator
							</Button>
							<Button
								variant='contained'
								component={RouterLink}
								to={testPath}
							>
								Test Calculator
							</Button>
						</Stack>
					</Grid>
				</Grid>
			</Grid>
			}
		</Box >
	);
};
