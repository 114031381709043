import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import { Loading } from './Loading';

export const RequireAuth = ({children}) => {
	const { isAuthenticated, isLoading } = useAuth0();

	if (isLoading) return(<Loading text="Loading..."/>);
	return (isAuthenticated ? children : <Navigate to="/" replace />);
};