import React from 'react';
import {
	Button,
	Grid,
	TextField,
	FormGroup,
	FormControl,
	FormControlLabel,
	Checkbox,
	Select,
	MenuItem,
	InputLabel
} from '@mui/material';
import {
	useModalContext,
	useCalculatorContext,
	useSnackbarContext
} from '../../utilities';
import { DeleteCalculationPrompt } from './';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

import { AutoCompleteInput } from './AutoComplete/AutoCompleteInput';
import camelCase from 'camelcase';

export const EditCalculationForm = ( {index} ) => {
	const { calculator, update, setUpdate, editCalculation} = useCalculatorContext();
	const {setModalChildren, showModal} = useModalContext();
	const expression = calculator.expressionList[index];
	const { showSnackbar } = useSnackbarContext();
	const calculatorId = useParams();
	const [formulaError, setFormulaError] = useState([]);
	const [formulaString, setFormulaString] = useState(expression.formulaString);
	const { parameterList } = calculator;
	const { register, handleSubmit, getValues, watch, formState: { errors }} = useForm({
		shouldUnregister: true,
		defaultValues: {
			calculatorId: Number(calculatorId.id),
			id: expression.id,
			name: expression.name,
			dataName: expression?.dataName,
			order: expression.order,
			formula: expression.formula,
			formulaString: expression?.formulaString,
			parameters: expression?.parameters,
			result: expression.result
		}
	});
	const checkBox = watch('result');
	const check = watch('result.roundResult');
	const handleFormulaDataUpdate = (value) => {
		setFormulaError([]);
		setFormulaString(value);
	};

	const compileFormula = () => {
		const parameterIds = [];
		const usedParameters = formulaString
			.split(' ')
			.filter((param) => param[0] === '@');
		let errors = [];
		usedParameters.forEach((usedParam) => {
			const matchedParam = parameterList.find(
				(param) => {
					return param.dataName === usedParam.slice(1).trim();}
			);
	
			if (!matchedParam ) {
				errors.push(`Parameter "${usedParam}" is not a valid parameter`);
				console.error();
			} else {
				if (matchedParam.id && !parameterIds.includes(matchedParam.id)) {
					parameterIds.push(matchedParam.id);
				}
			}
		});
		if(!formulaString.length > 0 ){
			errors.push('Please fill out this field');
		}
		setFormulaError(errors);
		return {
			error: errors.length > 0,
			formulaData: {
				parameters: parameterIds,
				formula: formulaString.replaceAll('@', ''),
				formulaString
			},
		};
	};
	
	const onSubmit = async  () => {
		let calculationBody = getValues();
		const {error, formulaData} = compileFormula();
		if(error) return;
		calculationBody.result = checkBox;
		calculationBody.dataName = camelCase(calculationBody.name); 
		calculationBody = {...calculationBody, ...formulaData};
		let response = await editCalculation(calculationBody);
		if (response && response.status === 204) {
			showSnackbar('Calculation saved', 'success');
			setUpdate(!update);
		} else {
			showSnackbar('Calculation did NOT save', 'error');
		}
	};

	const handleOpen = async () => {
		setModalChildren( <DeleteCalculationPrompt calculation={calculator.expressionList[index]} /> );
		showModal();
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container flexDirection='column' rowSpacing={2}>
				<Grid item xs={6}>
					<TextField
						required
						{...register('name', { required: true, value: expression.displayName })}
						variant='standard'
						fullWidth
					/>
				</Grid>
				<Grid item xs={6}>
					<FormControl  fullWidth>
						<InputLabel>Result parameter</InputLabel>
						<Select
							{...register('result.id', { required: true, value: expression.result?.id })}
							label='Result Parameter'
							error={errors?.result?.id ? true : false}
							defaultValue={expression.result?.id || ''}
						>
							{parameterList && parameterList.map(param => {
								if (param.inputType === 'result') {
									return <MenuItem key={param.id} value={param.id}>{param.name}</MenuItem>;
								}
							})}
						</Select>
					</FormControl>
				</Grid>
				<Grid item>
					<InputLabel sx={{fontWeight:600, fontSize:'14px'}}>Formula</InputLabel>
					<Grid item xs={12} sm={12}>
						<AutoCompleteInput
							label='Formula'
							id='formula'
							rows="4"
							value={expression?.formulaString === 'formula' ? '' : expression?.formulaString}
							error={formulaError}
							register={register}
							placeholder='Use @ to select parameter'
							onChange={handleFormulaDataUpdate}
						></AutoCompleteInput>
					</Grid>
				</Grid>
				<Grid container item justifyContent='space-between' flexDirection='row'>
					<Grid container item justifyContent='flex-start' xs={6} alignItems='flex-end' spacing={2}>
						<Grid item>
							<TextField
								fullWidth
								label='Decimals'
								{...register('result.roundPrecision', {
									required: getValues('result.roundResult'),
									value: expression.result?.roundPrecision,
									pattern: { value: /^[0-9]+$/i, message: 'Enter decimal places'}
								})}
								error={errors.result?.roundPrecision ? true : false}
								disabled={!check}
							/>
						</Grid>
						<Grid item alignSelf='center'>
							<FormGroup>
								<FormControlLabel
									labelPlacement='end'
									control={
										<Checkbox
											defaultChecked={expression.result?.roundResult}
											{...register('result.roundResult')}
										/>
									}
									label='Round result'/>
							</FormGroup>
						</Grid>
					</Grid>
					<Grid container item justifyContent='flex-end' alignItems='flex-end' spacing={2} xs={6}>
						<Grid item>
							<Button variant='contained' color='error' onClick={handleOpen}>Delete</Button>
						</Grid>
						<Grid item>
							<Button variant='contained' type='submit'>Save</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
};
