import React from 'react';
import {
	Button,
	Grid,
	Typography
} from '@mui/material';
import {
	useForm,
	FormProvider,
} from 'react-hook-form';
import {
	CreateDisplayCalculation
} from './inputFields/';
import {
	useModalContext,
	useSnackbarContext,
	useCalculatorContext
} from '../../utilities';

export const CreateConditionForm = ({expressionId}) => {
	const { calculator, createCondition, update, setUpdate } = useCalculatorContext();
	const { closeModal } = useModalContext();
	const { showSnackbar } = useSnackbarContext();
	const { formState: {isValid, errors}, ...methods } = useForm({
		mode: 'all',
		shouldUnregister: true,
		defaultValues: {
			calculatorId: calculator.calculatorId,
			conditionId: '',
			expressionId: expressionId,
			order: '',
			conditionLogicType: '',
			displayCalculation: '',
			rules: []
		}
	});

	const conditionBody = methods.watch();

	const onSubmit = async () => {
		conditionBody.calculatorId = calculator.calculatorId;
		conditionBody.expressionId = expressionId;
		let response = await createCondition(conditionBody);
		if (response) {
			setUpdate(!update);
			showSnackbar('New condition created', 'success');
		} else {
			showSnackbar('Error while saving conditions', 'error');
		}
		closeModal();
	};

	return (
		<FormProvider {...{formState: {isValid, errors}, ...methods}}>
			<Typography
				variant='h5'
				gutterBottom
			>Create Condition</Typography>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<Grid item container flexDirection='column' rowSpacing={2}>
					<Grid item xs={12}>
						<CreateDisplayCalculation expressionId={expressionId}/>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant='contained'
							fullWidth
							type='submit'
							disabled={!isValid}
						>
							Add Condition
						</Button>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	);
};
