import React from 'react';
import {
	Paper,
	Box,
	Button,
	Grid,
	Typography
} from '@mui/material';
import {
	useFormContext
} from 'react-hook-form';
import { FormStepInput } from '.';
import {
	useCalculatorContext,
} from '../../utilities';

export const FormStep = ({
	step,
	currentFormSteps,
	setCurrentFormSteps,
	currentStep,
	setCurrentStep,
	setResponseVariables,
	responseVariables,
	setShowResults,
	showResults,
	setDisabledSteps,
	disabledSteps
}) => {
	const { calculateStep, update, setUpdate, preview } = useCalculatorContext();
	const { handleSubmit, watch, reset } = useFormContext();

	const stepsBody = watch();

	const addNextSteps = (fromIndex) => {
		let newList = [];
		setDisabledSteps([...disabledSteps, ...currentFormSteps]);
		for (let i = fromIndex; i < preview?.formSteps?.length; i++) {
			let step = preview.formSteps[i];
			if (step.calculateStep) {
				newList = [...newList, step.order];
				setCurrentFormSteps([...currentFormSteps, ...newList]);
				setCurrentStep(step.order);
				break;
			}
			newList = [...newList, step.order];
		}
	};

	const checkRule = (rule, res) => {
		let parameterValue;
		step.inputs.forEach(input => {
			if (input.id === rule.resultParameter) {
				parameterValue = res.variables[input.dataName];
			}
		});
		let ruleValue = rule.conditionValue;

		switch (rule.operator) {
		case 'Equals':
			if (ruleValue == parameterValue) {
				return true;
			}
			return false;
		case 'NotEquals':
			if (ruleValue != parameterValue) {
				return true;
			}
			return false;
		case 'LessThan':
			if (ruleValue < parameterValue) {
				return true;
			}
			return false;
		case 'GreaterThan':
			if (ruleValue > parameterValue) {
				return true;
			}
			return false;
		case 'LessThanOrEquals':
			if (ruleValue <= parameterValue) {
				return true;
			}
			return false;
		case 'GreaterThanOrEquals':
			if (ruleValue >= parameterValue) {
				return true;
			}
			return false;
		default:
			break;
		}
	};

	const checkConditions = (condition, ruleList) => {
		let conditionalOrder;

		preview.formSteps.forEach(step => {
			if (step.dataName === condition.displayCalculation) {
				conditionalOrder = step.order;
			}
		});

		switch (condition.conditionLogicType) {
		case 'All':
			if (ruleList.includes(false)) {
				addNextSteps(step.order+1);
				setCurrentStep(step.order+1);
				break;
			}
			addNextSteps(conditionalOrder);
			setCurrentStep(conditionalOrder);
			break;
		case 'Any':
			if (ruleList.includes(true)) {	
				addNextSteps(conditionalOrder);
				setCurrentStep(conditionalOrder);
				break;
			}
			addNextSteps(step.order+1);
			setCurrentStep(step.order+1);
			break;
		default:
			break;
		}
	};

	const onSubmit = async () => {
		if (step.calculateStep && step.inputs.length > 0) {
			let res = await calculateStep(step.calculateEndpoint, stepsBody);
			if (res) {
				step.conditionList.forEach(condition => {
					let ruleList = [];
					condition.rules.forEach(rule => {
						ruleList.push(checkRule(rule, res));
					});
					checkConditions(condition, ruleList);
				});
				setResponseVariables({...responseVariables, ...res.variables});
			}
		} else if (step.calculateStep && step.inputs.length === 0) {
			setDisabledSteps([...disabledSteps, ...currentFormSteps]);
			let res = await calculateStep(step.calculateEndpoint, stepsBody);
			setResponseVariables({...responseVariables, ...res.variables});
			if (res) {
				setShowResults(true);
			}
		}
	};

	const resetForm = () => {
		setCurrentStep(0);
		setCurrentFormSteps([]);
		setShowResults(false);
		setDisabledSteps([]);
		reset();
		setUpdate(!update);
	};

	return (
		<Paper>
			<Box margin={2} padding={2}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid>
						<Typography variant='h5'>{step.name}</Typography>
					</Grid>
					<Grid item marginTop={2}>
						<Typography>{step.description}</Typography>
					</Grid>
					<Grid item container xs={12} columnSpacing={2} rowSpacing={2} justifyContent='flex-start' marginTop={2}>
						{
							step.inputs?.map(input => {
								return (
									<FormStepInput key={input.id} input={input} disabled={disabledSteps.includes(step.order)}/>
								);
							})
						}
					</Grid>
					{currentStep === step.order && step.order === 0 &&
						<Grid item container justifyContent='flex-end' marginTop={2}>
							<Grid item>
								<Button
									variant='contained'
									type='submit'
								>
									Next
								</Button>
							</Grid>
						</Grid>
					}
					{currentStep === step.order && step.order != 0 && step.inputs.length !== 0 &&
						<Grid item container justifyContent='flex-end' marginTop={2}>
							<Grid item>
								<Button
									variant='contained'
									type='submit'
								>
									Next
								</Button>
							</Grid>
						</Grid>
					}
					{currentStep === step.order && showResults &&
					<>
						<Grid item container justifyContent='space-between' marginTop={2}>
							<Grid item>
								<Typography variant='h5'>Results</Typography>
								{preview?.results.map(result => {
									if (result.dataName in responseVariables) {
										return (<Typography key={result.id}><b>{result.label}:</b> {responseVariables[result.dataName]}</Typography>);
									}
								})}
							</Grid>
							<Grid item>
								<Button
									variant='contained'
									onClick={resetForm}
								>
									Reset
								</Button>
							</Grid>
						</Grid>
					</>
					}
					{currentStep === step.order && step.inputs.length === 0 && !showResults &&
						<Grid item container justifyContent='flex-end' marginTop={2}>
							<Grid item>
								<Button
									variant='contained'
									type='submit'
								>
									Calculate
								</Button>
							</Grid>
						</Grid>
					}
				</form>
			</Box>
		</Paper>
	);
};