import React from 'react';
import { MyCalculatorsList } from '../components/calculators';
import {
	CalculatorProvider,
	ModalProvider,
	SnackbarProvider
} from '../utilities';
import { Grid } from '@mui/material';

export const MyCalculatorsPage = () => {

	return (
		<CalculatorProvider>
			<SnackbarProvider>
				<ModalProvider>
					<Grid container justifyContent='space-evenly' sx={{
						marginLeft: 'auto',
						marginRight: 'auto',
						maxWidth:'1280px'}}>
						<Grid item xs={10}>
							<MyCalculatorsList />
						</Grid>
					</Grid>
				</ModalProvider>
			</SnackbarProvider>
		</CalculatorProvider>
	);
};
