import {
	useState,
	useEffect
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useAccessToken = () => {
	const [accessToken, setAccessToken] = useState(null);
	const { getAccessTokenSilently } = useAuth0();
	
	const getAccessToken = async () => {
		try {
			const token = await getAccessTokenSilently({
				authorizationParams: {
					audience: process.env.REACT_APP_AUTH_AUDIENCE,
					scope: 'read:current_user',
				},
			});
			setAccessToken(token);
		} catch (e) {
			console.error(e.message);
			setAccessToken(null);
		}
	};
	useEffect(() => {
		getAccessToken();
	}, [getAccessTokenSilently]);
  
	return accessToken;
};
