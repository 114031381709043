import React, { useEffect, useState } from 'react';
import {
	Box,
	Typography,
	Grid,
} from '@mui/material';
import {
	useCalculatorContext
} from '../../utilities';
import {
	CreateParameterButton,
} from './';
import { Loading } from '../Loading';
import { ParameterAccordion } from './ParameterAccordion';

export const ParameterList = () => {
	const { calculator, loading } = useCalculatorContext();
	const [constants, setConstants] = useState([]);
	const [inputs, setInputs] = useState([]);
	const [results, setResults] = useState([]);
	const [selects, setSelects] = useState([]);
	useEffect(()=>{
		if(loading) return;
		setConstants([]);
		setResults([]);
		setInputs([]);
		setSelects([]);
		if (calculator?.parameterList) {
			calculator?.parameterList.forEach(parameter => {
				switch (parameter.inputType) {
				case 'constant':
					setConstants(prevState =>[...prevState, parameter]);
					break;
				case 'select':
					setSelects(prevState =>[...prevState, parameter]);
					break;
				case 'input':
					setInputs(prevState =>[...prevState, parameter]);
					break;
				case 'result':
					setResults(prevState =>[...prevState, parameter]);
					break;
				}
			});
		}
	},[calculator, loading]);
	return (
		<Box sx={{
			px: 4,
			pt: 5,
			borderRight: '1px solid #000',
			height: '100%'
		}}>
			{loading && (
				<Loading text="Loading.." align="start"/>
			)}
			{!loading &&
			<Grid item container marginBottom={2} justifyContent='space-between'>
				<Grid item>
					<Typography variant='h5' component='h1'>Parameters</Typography>
				</Grid>
				<Grid item>
					<CreateParameterButton />
				</Grid>
			</Grid>
			}
			
			{(!loading && (
				<Box pb={2}>
					{constants && constants.length > 0 && <ParameterAccordion parameters={constants} label="Constants" />}
					{inputs && inputs.length > 0 && <ParameterAccordion parameters={inputs} label="Inputs" />}
					{selects && selects.length > 0 &&<ParameterAccordion parameters={selects} label="Selects" />}
					{results && results.length > 0 && <ParameterAccordion parameters={results} label="Results" />}
				</Box>
			))}
		</Box>
	);
};