import React from 'react';
import {
	FormControl,
	FormControlLabel,
	RadioGroup,
	Radio,
	FormLabel
} from '@mui/material';
import { useFormContext } from 'react-hook-form';

export const LogicType = () => {
	const { register, watch } = useFormContext();
	
	return (
		<FormControl>
			<FormLabel>Apply rules</FormLabel>
			<RadioGroup
				row
				value={watch('conditionLogicType') || ''}
			>
				<FormControlLabel {...register('conditionLogicType')} label='All' value='All' control={<Radio />} />
				<FormControlLabel {...register('conditionLogicType')} label='Any' value='Any' control={<Radio />} />
			</RadioGroup>
		</FormControl>
	);
};
