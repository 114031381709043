import React from 'react';
import { IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DeleteParameterPrompt } from './';
import {
	useModalContext,
	useCalculatorContext
} from '../../utilities';

export const DeleteParameterButton = ({parameter}) => {
	const { showModal, setModalChildren } = useModalContext();
	const { getParameter } = useCalculatorContext();
	const handleOpen = async () => {
		await getParameter(parameter.id);
		setModalChildren( <DeleteParameterPrompt /> );
		showModal();
	};

	return (	
		<IconButton onClick={handleOpen} edge='end'>
			<DeleteForeverIcon />
		</IconButton>
	);
};
