import React from 'react';
import {
	Typography,
	Box,
	Grid
} from '@mui/material';
import {
	LoginButton,
	LogoutButton
} from './';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import logo from '../../assets/pentia_logo.svg';

export const Header = () => {
	const { isAuthenticated } = useAuth0();
	return (
		<Box component='header' sx={{
			p: 2,
			borderBottom: 'solid black 1px'
		}}>
			<Grid container spacing={2}>
				<Grid item xs={4} sm={1} sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
					<RouterLink to='/'>
						<Box
							component='img'
							sx={{
								height: 50,
								width: 50,
							}}
							alt='Logo'
							src={logo}
						/>
					</RouterLink>
				</Grid>
				<Grid item xs={8} sm={7} sx={{
					display: 'flex',
					alignItems: 'center'
				}}>
					<Typography variant='h1' component={RouterLink} to='/' sx={{
						textDecoration: 'none',
						fontSize: 32,
						'&:visited': {
							color: 'inherit'
						}
					}}>
						CalcGenie
					</Typography>
				</Grid>
				<Grid item xs={12} sm={4}sx={{
					display: 'flex',
					justifyContent: 'flex-end',
					alignItems: 'center'
				}}>
					{ isAuthenticated ?
						<>
							<RouterLink to='/myCalculators'>
							My calculators
							</RouterLink>
						
							<LogoutButton/>
						</>
						:
						<LoginButton/> 
					}
				</Grid>
			</Grid>
		</Box>
	);
};
