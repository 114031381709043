import React from 'react';
import {
	Box,
	Grid,
	IconButton,
	Typography
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
	ConditionalParameter,
	Operator,
	ConditionValue
} from './';

export const Rule = ({ index, remove, expressionId, expression }) => {

	return (
		<Box xs={12} width='100%'>
			<Grid item container flexDirection='column' rowSpacing={2} marginTop={2} borderTop={1}>
				<Grid item container columnSpacing={2}>
					<Grid item container xs={1} alignSelf='center' justifyContent='center'>
						<Typography sx={{ fontWeight: 'bold' }}>{index+1}</Typography>
					</Grid>
					<Grid item xs={6}>
						<ConditionalParameter index={index} expressionId={expressionId} expression={expression}/>
					</Grid>
					<Grid item xs={2}>
						<Operator index={index}/>
					</Grid>
					<Grid item xs={2}>
						<ConditionValue index={index}/>
					</Grid>
					<Grid item xs={1} alignSelf='center'>
						<IconButton
							variant='contained'
							onClick={() => remove(index)}
						>
							<DeleteForeverIcon />
						</IconButton>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};