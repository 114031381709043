import React from 'react';
import {
	useFormContext
} from 'react-hook-form';
import {
	Paper,
	Grid,
	Typography,
	Box,
	Button
} from '@mui/material';
import {
	useCalculatorContext,
	useSnackbarContext
} from '../../utilities';
import { TestInput } from '.';

export const TestCalculation = ({expression, parameterList, index, showFields, showLimit, result, setResult}) => {
	const { calculateExpression } = useCalculatorContext();
	const { handleSubmit, watch, formState: { isDirty, isValid } } = useFormContext();
	const { showSnackbar } = useSnackbarContext();
	const calculationIndex = index;
	let i = -1;
	
	const testBody = watch();

	const calculateMultiple = async (calculations) => {
		let results = {};
		for (let i = 0; i < Object.keys(calculations).length; i++) {
			let calcBody = {...calculations[i]};
			Object.assign(calcBody.variables, results);
			let res = await calculateExpression(calculations[i]);
			if (!res) {
				showSnackbar('Calculation Error', 'error');
				break;
			}
			Object.assign(results, res.result);
		}
		setResult(results);
		showSnackbar('Calculation Complete', 'success');
	};

	const onSubmit = async () => {
		if (index === 0) {
			let res = await calculateExpression(testBody.calculation[index]);
			setResult(res.result);
			if (res.status === 200) {
				showSnackbar('Calculation Complete', 'success');
			}
		} else if (index >= 1) {
			await calculateMultiple(testBody.calculation);
		}
	};

	const renderFields = (e) => {
		showFields(e.target.value);
	};

	return (
		<Paper>
			<Box padding={2} marginTop={2}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container item justifyContent='space-between'>
						<Grid item>
							<Typography variant='h5'>
								{expression.name}
							</Typography>
						</Grid>
						{!(showLimit == index) &&
						<Grid item alignSelf='flex-end'>
							<Button
								value={index}
								variant='contained'
								onClick={(e) => renderFields(e)}
							>
							Test
							</Button>
						</Grid>
						}
						{(showLimit >= index) &&
						<>
							{
								parameterList?.map(parameter => {
									if (expression.parameters.includes(parameter.id) && (parameter.inputType !== 'constant')) {
										i += 1;
										return (
											<Grid key={parameter.id} container flexDirection='column' item xs={12} marginTop={2}>
												<TestInput parameter={parameter} index={i} calculationIndex={calculationIndex} expression={expression}/>
											</Grid>
										);
									}
								})
							}
							<Grid flexDirection='column' justifyContent='space-between' container item xs={12}>
								<Grid item marginTop={2}>
									<Typography>
										<b>Formula: </b>{expression.formula}
									</Typography>
								</Grid>
								<Grid item marginTop={2}>
									<b>Result: </b>
									{ (Object.keys(result).length > 0) &&
										parameterList?.map(parameter => {
											if (parameter.dataName && (expression.result.id === parameter.id)) {
												return result[parameter.dataName];
											}
										})
									}
								</Grid>
								{(showLimit == index) &&
								<Grid item alignSelf='flex-end'>
									<Button
										id={index}
										variant='contained'
										type='submit'
										disabled={!isDirty || !isValid || (expression.parameters.length < 2)}
									>
									Calculate
									</Button>
								</Grid>
								}
							</Grid>
						</>
						}
					</Grid>
				</form>
			</Box>
		</Paper>
	);
};
