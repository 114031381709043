import React from 'react';
import { Button } from '@mui/material';
import { EditParameterForm } from './';
import { useModalContext, useCalculatorContext } from '../../utilities';

export const EditParameterButton = (
	{ parameter }
) => {
	const { showModal, setModalChildren } = useModalContext();
	const { getParameter} = useCalculatorContext();
	const handleOpen = async () => {
		await getParameter(parameter.id);
		showModal();
		setModalChildren( <EditParameterForm /> );
	};
	
	return (
		<Button
			onClick={handleOpen}
			variant='contained'
			fullWidth
		>
			{parameter.name}
		</Button>
	);
};
