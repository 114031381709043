import React from 'react';
import {
	Grid,
	Box,
	Button,
	Typography
} from '@mui/material';
import {
	useModalContext,
	useCalculatorContext,
	useSnackbarContext
} from '../../utilities';

export const DeleteParameterPrompt = () => {
	const { currentParameter, setUpdate, update, deleteParameter } = useCalculatorContext();
	const { closeModal } = useModalContext();
	const { showSnackbar } = useSnackbarContext();

	const handleDelete = async () => {
		let res = await deleteParameter(currentParameter.id);
		if (res && res.status === 200) {
			showSnackbar('Parameter deleted', 'success');
			setUpdate(!update);
		} else {
			showSnackbar('Error - Parameter NOT deleted', 'error');
		}
		closeModal();
	};

	return (
		<Box>
			<Grid container justifyContent='center' spacing={2}>
				<Grid item xs={12} textAlign='center'>
					<Typography>Do you want to permanently delete <br /><b>{currentParameter.name}</b>?</Typography>
				</Grid>
				<Grid item>
					<Button variant='contained' color='warning' onClick={handleDelete}>delete</Button>
				</Grid>
				<Grid item>
					<Button variant='contained' onClick={closeModal}>cancel</Button>
				</Grid>
			</Grid>
		</Box>
	);
};
