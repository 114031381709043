import React from 'react';
import {
	TextField,
	Button,
	Grid,
	Typography
} from '@mui/material';
import {
	useForm
} from 'react-hook-form';
import {
	useSnackbarContext,
	useCalculatorContext,
	useModalContext
} from '../../utilities';

export const CreateCalculatorForm = () => {
	const { register, handleSubmit, watch } = useForm({
		shouldUnregister: true
	});
	const calculatorBody = watch();
	const { showSnackbar } = useSnackbarContext();
	const { setUpdate, update, createCalculator } = useCalculatorContext();
	const { closeModal } = useModalContext();

	const onSubmit = async () => {
		let response = await createCalculator(calculatorBody);
		if (response && response.id) {
			showSnackbar('New calculator created', 'success');
			setUpdate(!update);
		} else {
			showSnackbar('Error while creating new calculator', 'error');
		}
		closeModal();
	};
	
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container flexDirection='column' rowGap={2}>
				<Grid item xs={12}>
					<Typography variant="h4">
						Create Calculator
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						{...register('name', { required: true })}
						variant='outlined'
						label='Calculator name'
						required
						fullWidth
					/>
				</Grid>
				<Grid item alignSelf='flex-end'>
					<Button variant='contained' type='submit' fullWidth>Create</Button>
				</Grid>
			</Grid>
		</form>
	);
};