import React from 'react';
import { Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { Puff } from 'react-loader-spinner';

export const LoginButton = () => {
	const { loginWithRedirect, isLoading } = useAuth0();
	return (
		<Button onClick={() => loginWithRedirect()} variant='outlined' sx={{
			ml: 2,
			minWidth:'6rem'
		}}>
			{isLoading ? (<Puff width={20} height={20}></Puff>)
				:<span>Login</span>}
		</Button>
	);
};
