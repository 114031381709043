import React from 'react';
import { Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

export const LogoutButton = () => {
	const { logout } = useAuth0();
	return (
		<Button onClick={() => logout({logoutParams: {returnTo: window.location.origin}})} variant='outlined' sx={{
			ml: 2,
			minWidth:'6rem'
		}}>Log Out</Button>
	);
};
